var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "v-toolbar",
        { attrs: { flat: "", color: "fill" } },
        [
          _c("span", { staticClass: "text-center ml-2" }, [
            _vm._v("Store Allowance Totals"),
          ]),
          _c("v-spacer"),
          _c(
            "v-col",
            { staticClass: "pl-0", attrs: { cols: "3" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Search Recap",
                  "prepend-inner-icon": "mdi-magnify",
                  outlined: "",
                  dense: "",
                  "background-color": "input",
                  clearable: "",
                  "hide-details": "",
                },
                model: {
                  value: _vm.filter,
                  callback: function ($$v) {
                    _vm.filter = $$v
                  },
                  expression: "filter",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-divider"),
      _c("v-data-table", {
        attrs: {
          height: _vm.tableSize,
          loading: _vm.loading,
          headers: _vm.headers,
          items: _vm.stores,
          "items-per-page": _vm.pageSize,
          color: "#fff",
          "loading-text": "Loading... Please wait",
          "fixed-header": "",
          dense: "",
          "item-key": "party_id",
          "sort-by": "store_name_num",
          search: _vm.filter,
          "hide-default-footer": "",
        },
        on: {
          "update:items": function ($event) {
            _vm.stores = $event
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "item.allowance_earned",
              fn: function (ref) {
                var item = ref.item
                return [
                  _c("span", [
                    _vm._v(
                      "$" +
                        _vm._s(_vm.formatCurrencyDisplay(item.allowance_earned))
                    ),
                  ]),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
      _c("BatchTableFooter", {
        attrs: {
          currentPage: _vm.page,
          itemsPerPage: _vm.pageSize,
          hasNextPage: !_vm.disableNext,
          itemsPerPageOptions: _vm.pageSizes,
        },
        on: {
          "previous-page": _vm.previous,
          "next-page": _vm.next,
          "update:items-per-page": _vm.updatePageSize,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
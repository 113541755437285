<template>
  <v-card flat>
    <v-toolbar flat color="fill">
      <span class="text-center ml-2">Store Allowance Totals</span>
      <v-spacer></v-spacer>
      <v-col cols="3" class="pl-0">
        <v-text-field
          v-model="filter"
          label="Search Recap"
          prepend-inner-icon="mdi-magnify"
          outlined
          dense
          background-color="input"
          clearable
          hide-details>
        </v-text-field>
      </v-col>
    </v-toolbar>
    <v-divider></v-divider>
    <v-data-table
      :height="tableSize"
      :loading="loading"
      :headers="headers"
      :items.sync="stores"
      :items-per-page="pageSize"
      color="#fff"
      loading-text="Loading... Please wait"
      fixed-header
      dense
      item-key="party_id"
      sort-by="store_name_num"
      :search="filter"
      hide-default-footer>
      <template #[`item.allowance_earned`]="{item}">
        <span>${{ formatCurrencyDisplay(item.allowance_earned) }}</span>
      </template>
    </v-data-table>

    <!-- BatchTableFooter for pagination -->
    <BatchTableFooter
      :currentPage="page"
      :itemsPerPage="pageSize"
      :hasNextPage="!disableNext"
      :itemsPerPageOptions="pageSizes"
      @previous-page="previous"
      @next-page="next"
      @update:items-per-page="updatePageSize"
    />
  </v-card>
</template>
<script>
// Mixins
import { fullWidth } from '@/mixins/fullWidth'
import { format } from '@/mixins/format'
import { displayAlert } from '@/mixins/displayAlert'
import BatchTableFooter from '@/components/BatchTables/SharedElements/BatchTableFooter.vue';

export default {
  name: 'store-table',
  data() {
    return {
      filter: '',
      loading: false,
      search: '',
      stores: [],
      page: 1,
      pageSize: 50,
      pageSizes: [50, 100, 200],
    };
  },
  components: { BatchTableFooter },
  mixins: [fullWidth, format, displayAlert],
  props: ['panel_height', 'selectedBatch'],
  watch: {
    filter: {
      handler () {
        if (this.filter === null) {
          this.filter = ''
        }
      }
    },
    panel_height: {
      handler (value) {
        this.nonTableHeight = value
      } 
    },
    pageSize: {
      handler() {
        if (this.page > 1) this.page = 1;
        this.getStoreRecap();
      },
    },
  },
  created () {
    this.nonTableHeight = this.panel_height
    this.getStoreRecap()
  },
  computed: {
    offset() {
      return (this.page - 1) * this.pageSize;
    },
    disableNext() {
      return this.stores.length < this.pageSize;
    },
    headers () {
      return [
        { sortable: false, filterable: false },
        { text: 'Store Name/Number', sortable: true, filterable: true, value: 'store_name_num', class: 'black--text' },
        { text: 'Total Allowance', align: 'left', sortable: true, filterable: true, value: 'allowance_earned', class: 'black--text' }
      ]
    }
  },
  methods: {
    async getStoreRecap() {
    const { id } = this.selectedBatch;
    this.loading = true;
    try {
      const response = await this.$BillingBatch.getStoreRecaps(id, this.pageSize, this.offset);
      if (response?.data) {
        this.stores = response.data;
        this.totalItems = response.data.total || this.stores.length;
      }
    } catch (err) {
      this.handleError(err);
    } finally {
      this.loading = false;
    }
  },

    updatePageSize(size) {
      this.pageSize = size;
      this.page = 1;
      this.getStoreRecap();
    },
    previous() {
      if (this.page > 1) {
        this.page -= 1;
        this.getStoreRecap();
      }
    },
    next() {
      if (!this.disableNext) {
        this.page += 1;
        this.getStoreRecap();
      }
    },
  },
};
</script>

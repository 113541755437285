var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { attrs: { justify: "center", value: _vm.value, persistent: "" } },
    [
      _c(
        "v-card",
        { attrs: { color: "#fafafa" } },
        [
          _c(
            "v-container",
            { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
            [
              _c(
                "v-expansion-panels",
                {
                  attrs: { flat: "" },
                  model: {
                    value: _vm.expandedPanel,
                    callback: function ($$v) {
                      _vm.expandedPanel = $$v
                    },
                    expression: "expandedPanel",
                  },
                },
                [
                  _c(
                    "v-expansion-panel",
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "", color: "main" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-expansion-panel-header",
                                    _vm._g(
                                      {
                                        attrs: { color: "grey lighten-3" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "v-row",
                                                    {
                                                      attrs: {
                                                        align: "center",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: { icon: "" },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.$emit(
                                                                "update:value",
                                                                false
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v("mdi-close"),
                                                          ]),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-card-title",
                                                        { staticClass: "mt-0" },
                                                        [
                                                          _vm._v(
                                                            " Billing Recaps "
                                                          ),
                                                        ]
                                                      ),
                                                      _c("v-spacer"),
                                                      _vm.show
                                                        ? _c(
                                                            "small",
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "Batch Dates:"
                                                                ),
                                                              ]),
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .selectedBatch
                                                                      .start_date
                                                                  ) +
                                                                  " - " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .selectedBatch
                                                                      .end_date
                                                                  ) +
                                                                  " "
                                                              ),
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "#000",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-circle-small"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "Batch Name:"
                                                                ),
                                                              ]),
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .selectedBatch
                                                                      .name
                                                                  ) +
                                                                  " "
                                                              ),
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "#000",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-circle-small"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "Batch Type:"
                                                                ),
                                                              ]),
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .selectedBatch
                                                                      .batch_type
                                                                  ) +
                                                                  " "
                                                              ),
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "#000",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-circle-small"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "Responsible Party:"
                                                                ),
                                                              ]),
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.getResponsiblePartyName(
                                                                      _vm
                                                                        .selectedBatch
                                                                        .responsible_party_id
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                              _vm.selectedBatch
                                                                .status
                                                                ? _c(
                                                                    "span",
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                "#000",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-circle-small"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "strong",
                                                                        [
                                                                          _vm._v(
                                                                            "Status:"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm
                                                                              .selectedBatch
                                                                              .status
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _c("v-spacer"),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      on
                                    )
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c(
                            "span",
                            [
                              _c(
                                "v-icon",
                                { attrs: { small: "", color: "#fff" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.show
                                          ? "mdi-arrow-expand-vertical"
                                          : "mdi-arrow-collapse-vertical"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.show
                                      ? "Expand Batch Details"
                                      : "Collapse Batch Details"
                                  ) +
                                  " "
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "v-expansion-panel-content",
                        { attrs: { color: "grey lighten-3" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      value: _vm.selectedBatch.name,
                                      label: "Batch Name",
                                      outlined: "",
                                      readonly: "",
                                      "hide-details": "",
                                      "background-color": "input",
                                      dense: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "2" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      value: _vm.formatDate(
                                        _vm.selectedBatch.start_date
                                      ),
                                      label: "Start Date",
                                      "prepend-inner-icon": "mdi-calendar",
                                      outlined: "",
                                      readonly: "",
                                      "hide-details": "",
                                      "background-color": "input",
                                      dense: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "2" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      value: _vm.formatDate(
                                        _vm.selectedBatch.end_date
                                      ),
                                      label: "End Date",
                                      "prepend-inner-icon": "mdi-calendar",
                                      outlined: "",
                                      readonly: "",
                                      "hide-details": "",
                                      "background-color": "input",
                                      dense: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "2" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      value: _vm.formatDateTime(
                                        _vm.selectedBatch.last_update_date
                                      ),
                                      label: "Last Updated",
                                      outlined: "",
                                      readonly: "",
                                      "hide-details": "",
                                      "background-color": "input",
                                      dense: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "3" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      value: _vm.selectedBatch.batch_type,
                                      label: "Batch Type",
                                      outlined: "",
                                      readonly: "",
                                      "background-color": "input",
                                      dense: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "3" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      value: _vm.respParty,
                                      label: "Responsible Party",
                                      outlined: "",
                                      readonly: "",
                                      "background-color": "input",
                                      dense: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      value: _vm.selectedBatch.status,
                                      label: "Status",
                                      outlined: "",
                                      readonly: "",
                                      "background-color": "input",
                                      dense: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "#FFB74D" },
                                      on: {
                                        click: function ($event) {
                                          _vm.modal = true
                                        },
                                      },
                                    },
                                    [_vm._v(" Rebill/Reverse ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        disabled: _vm.invalidStatus,
                                        color: "success",
                                      },
                                      on: { click: _vm.downloadRecaps },
                                    },
                                    [_vm._v(" Download Recaps ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              [
                _c("v-divider"),
                _c(
                  "v-tabs",
                  {
                    attrs: {
                      grow: "",
                      dark: "",
                      "background-color": "#37474F",
                      height: "40",
                      "hide-slider": "",
                      "active-class": "active",
                    },
                    model: {
                      value: _vm.tab,
                      callback: function ($$v) {
                        _vm.tab = $$v
                      },
                      expression: "tab",
                    },
                  },
                  _vm._l(_vm.currentTabs, function (item) {
                    return _c(
                      "v-tab",
                      { key: item, attrs: { ripple: false } },
                      [_vm._v(" " + _vm._s(item) + " ")]
                    )
                  }),
                  1
                ),
                _c(
                  "keep-alive",
                  [
                    _c(_vm.activeTab.component, {
                      tag: "component",
                      attrs: {
                        selectedBatch: _vm.selectedBatch,
                        panel_height: _vm.panelHeight + _vm.footerHeight,
                      },
                    }),
                  ],
                  1
                ),
              ],
            ],
            2
          ),
        ],
        1
      ),
      _vm.modal
        ? _c("RebillReverse", {
            attrs: { value: _vm.modal, selectedBatch: _vm.selectedBatch },
            on: {
              "update:value": function ($event) {
                _vm.modal = $event
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <v-card flat>
    <v-toolbar flat color="fill">
      <ToggleExpanded :refs="$refs"></ToggleExpanded>
      <v-divider vertical inset class="mx-4" />
      <span class="text-center mr-3">
        Store Totals by Department
      </span>
      <v-col cols="3">
        <v-autocomplete
          v-model="selectedStore"
          label="Store Filter"
          :items="stores"
          background-color="input"
          hide-details
          clearable
          outlined
          dense>
        </v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          v-model="selectedDepartment"
          label="Department Filter"
          :items="departments"
          background-color="input"
          hide-details
          clearable
          outlined
          dense>
        </v-autocomplete>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="3" class="pl-0">
        <v-text-field
          :label="filter ? 'Press Enter to Search' : 'Search Recap'"
          prepend-inner-icon="mdi-magnify"
          hide-details
          outlined
          dense
          background-color="input"
          v-model="filter"
          clearable>
        </v-text-field>
      </v-col>
    </v-toolbar>
    <v-divider></v-divider>
    <v-data-table
      :height="tableSize"
      :loading="loading"
      :headers="headers"
      :items.sync="currentRecaps"
      :items-per-page.sync="pageSize"
      color="#fff"
      loading-text="Loading... Please wait"
      fixed-header
      :group-by="groupKey"
      dense
      :search="filter"
      hide-default-footer>
      <template v-slot:[`group.header`]="{ group, headers, toggle, isOpen }">
        <td :colspan="headers.length">
          <v-row dense>
            <v-btn @click="toggle" small icon :ref="group" :data-open="isOpen">
              <v-icon>{{ isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
            <v-col>{{ group }}</v-col>
            <v-col cols="auto" class="ml-auto mr-6">
              <span style="display: inline-block">
                Total Allowance: ${{ sumAllowance(groupKey, group, currentRecaps) }}
              </span>
            </v-col>
          </v-row>
        </td>
      </template>

      <template #[`item.allowance_earned`]="{item}">
        <span>${{ formatCurrencyDisplay(item.allowance_earned) }}</span>
      </template>
    </v-data-table>

    <!-- BatchTableFooter for pagination -->
    <BatchTableFooter
      :currentPage="page"
      :itemsPerPage="pageSize"
      :hasNextPage="!disableNext"
      :itemsPerPageOptions="pageSizes"
      @previous-page="previous"
      @next-page="next"
      @update:items-per-page="updatePageSize"
    />
  </v-card>
</template>
<script>
// Components
import ToggleExpanded from '@/components/ToggleExpanded.vue'
import BatchTableFooter from '@/components/BatchTables/SharedElements/BatchTableFooter.vue';
// Mixins
import { fullWidth } from '@/mixins/fullWidth'
import { format } from '@/mixins/format'
import { utils } from '@/mixins/utils'
import { displayAlert } from '@/mixins/displayAlert'
export default {
  name: 'department-table',
  data () {
    return {
      filter: '',
      loading: false,
      search:'',
      departmentRecaps: [],
      selectedDepartment: '',
      selectedStore: '',
      groupKey: 'store_name_num',
      page: 1,
      pageSize: 50,
      pageSizes: [50, 100, 200],
      totalItems: 0, 
    };
  },
  components: { ToggleExpanded, BatchTableFooter },
  mixins: [fullWidth, format, utils, displayAlert],
  props: ['panel_height', 'selectedBatch'],
  watch: {
    filter: {
      handler () {
        if (this.filter === null) {
          this.filter = ''
        }
      }
    },
    panel_height: {
      handler (value) {
        this.nonTableHeight = value
      } 
    }
  },
  pageSize: {
    handler() {
      if (this.page > 1) this.page = 1;
      this.getDepartmentRecaps();
    },
  },
  created () {
    this.nonTableHeight = this.panel_height;
    this.getDepartmentRecaps();
  },
  computed: {
    offset() {
      return (this.page - 1) * this.pageSize;
    },
    disableNext() {
      return this.departmentRecaps.length < this.pageSize && this.offset + this.departmentRecaps.length >= this.totalItems;
    },
    currentRecaps () {
      let recaps = this.departmentRecaps
      if (this.selectedDepartment) {
        recaps = recaps.filter(r => r?.department === this.selectedDepartment)
      }
      if (this.selectedStore) {
        recaps = recaps.filter(r => r?.store_name_num === this.selectedStore)
      }
      return recaps
    },
    departments () {
      return (this.departmentRecaps.length > 0)
        ? this.getUniqueByKey(this.departmentRecaps, 'department')
        : []
    },
    stores () {
      return (this.departmentRecaps.length > 0)
        ? this.getUniqueByKey(this.departmentRecaps, 'store_name_num')
        : []
    },
    headers () {
      return [
        { sortable: false, filterable: false },
        { text: 'Store', align: 'left', sortable: true, filterable: true, value: 'store_name_num', class: 'black--text' },
        { text: 'Department', align: 'left', sortable: true, filterable: true, value: 'department', class: 'black--text' },
        { text: 'Department Allowance', align: 'left', sortable: true, filterable: true, value: 'allowance_earned', class: 'black--text' },
      ]
    }
  },
  methods: {
    async getDepartmentRecaps() {
      const { id } = this.selectedBatch;
      this.loading = true;
      try {
        const response = await this.$BillingBatch.getDepartmentRecaps(id, this.pageSize, this.offset);
        if (response?.data) {
          this.departmentRecaps = response.data;
          this.totalItems = response.data.total || this.departmentRecaps.length;
        }
      } catch (err) {
        this.handleError(err);
      } finally {
        this.loading = false;
      }
    },
    updatePageSize(size) {
      this.pageSize = size;
      this.page = 1;
      this.getDepartmentRecaps();
    },
    previous() {
      if (this.page > 1) {
        this.page -= 1;
        this.getDepartmentRecaps();
      }
    },
    next() {
      if (!this.disableNext) {
        this.page += 1;
        this.getDepartmentRecaps();
      }
    },
  },
};
</script>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "v-toolbar",
        { attrs: { flat: "", color: "fill" } },
        [
          _c("ToggleExpanded", { attrs: { refs: _vm.$refs } }),
          _c("v-divider", {
            staticClass: "mx-4",
            attrs: { vertical: "", inset: "" },
          }),
          _c("span", { staticClass: "text-center" }, [
            _vm._v(" Ineligible Items "),
          ]),
          _c("v-spacer"),
          _c(
            "v-col",
            { staticClass: "pl-0", attrs: { cols: "3" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Search Recap",
                  "prepend-inner-icon": "mdi-magnify",
                  outlined: "",
                  dense: "",
                  "background-color": "input",
                  clearable: "",
                  "hide-details": "",
                },
                model: {
                  value: _vm.filter,
                  callback: function ($$v) {
                    _vm.filter = $$v
                  },
                  expression: "filter",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-divider"),
      _c("v-data-table", {
        attrs: {
          height: _vm.tableSize,
          loading: _vm.loading,
          headers: _vm.headers,
          items: _vm.ineligible_items,
          "items-per-page": _vm.pageSize,
          "items-length": _vm.totalItems,
          page: _vm.currentPage,
          "footer-props": { "items-per-page-options": _vm.pageSizes },
          color: "#fff",
          "loading-text": "Loading... Please wait",
          "fixed-header": "",
          dense: "",
          "hide-default-footer": "",
          "item-key": "party_id",
          "group-by": "store_name_number",
          search: _vm.filter,
        },
        on: {
          "update:page": [
            function ($event) {
              _vm.currentPage = $event
            },
            _vm.updatePage,
          ],
        },
        scopedSlots: _vm._u(
          [
            {
              key: "group.header",
              fn: function (ref) {
                var group = ref.group
                var headers = ref.headers
                var toggle = ref.toggle
                var isOpen = ref.isOpen
                return [
                  _c(
                    "td",
                    { attrs: { colspan: headers.length } },
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              ref: group,
                              attrs: {
                                small: "",
                                icon: "",
                                "data-open": isOpen,
                              },
                              on: { click: toggle },
                            },
                            [
                              _c("v-icon", [
                                _vm._v(
                                  _vm._s(
                                    isOpen
                                      ? "mdi-chevron-up"
                                      : "mdi-chevron-down"
                                  )
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c("v-col", [_vm._v(_vm._s(group))]),
                          _c(
                            "v-col",
                            {
                              staticStyle: {
                                display: "grid",
                                "justify-content": "flex-end",
                                "padding-right": "30px",
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { display: "inline-block" } },
                                [
                                  _vm._v(
                                    " Total Missed Allowance: $" +
                                      _vm._s(_vm.sumMissedAllowance(group)) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "item.missed_allowance",
              fn: function (ref) {
                var item = ref.item
                return [
                  _c("span", [
                    _vm._v(
                      "$" +
                        _vm._s(_vm.formatCurrencyDisplay(item.missed_allowance))
                    ),
                  ]),
                ]
              },
            },
            {
              key: "item.variance",
              fn: function (ref) {
                var item = ref.item
                return [
                  _c("span", [
                    _vm._v(
                      "$" + _vm._s(_vm.formatCurrencyDisplay(item.variance))
                    ),
                  ]),
                ]
              },
            },
            {
              key: "item.promo_unit_price",
              fn: function (ref) {
                var item = ref.item
                return [
                  _c("span", [
                    _vm._v(
                      "$" +
                        _vm._s(_vm.formatCurrencyDisplay(item.promo_unit_price))
                    ),
                  ]),
                ]
              },
            },
            {
              key: "item.basket_unit_price",
              fn: function (ref) {
                var item = ref.item
                return [
                  _c("span", [
                    _vm._v(
                      "$" +
                        _vm._s(
                          _vm.formatCurrencyDisplay(item.basket_unit_price)
                        )
                    ),
                  ]),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
      _c("BatchTableFooter", {
        attrs: {
          currentPage: _vm.currentPage,
          itemsPerPage: _vm.pageSize,
          hasNextPage: _vm.hasNextPage,
          itemsPerPageOptions: _vm.pageSizes,
        },
        on: {
          "previous-page": _vm.previousPage,
          "next-page": _vm.nextPage,
          "update:items-per-page": _vm.updatePageSize,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
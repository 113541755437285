<template>
  <v-card flat>
    <v-toolbar flat color="fill">
      <ToggleExpanded :refs="$refs"></ToggleExpanded>
      <v-divider vertical inset class="mx-4" />
      <span class="text-center">
        Ineligible Items
      </span>
      <v-spacer></v-spacer>
      <v-col cols="3" class="pl-0">
        <v-text-field
          v-model="filter"
          label="Search Recap"
          prepend-inner-icon="mdi-magnify"
          outlined
          dense
          background-color="input"
          clearable
          hide-details>
        </v-text-field>
      </v-col>
    </v-toolbar>
    <v-divider></v-divider>
    <v-data-table
    :height="tableSize"
    :loading="loading"
    :headers="headers"
    :items="ineligible_items"
    :items-per-page="pageSize"
    :items-length="totalItems"
    :page.sync="currentPage"
    :footer-props="{'items-per-page-options': pageSizes}"
    color="#fff"
    loading-text="Loading... Please wait"
    fixed-header
    dense
    hide-default-footer
    item-key="party_id"
    group-by="store_name_number"
    :search="filter"
    @update:page="updatePage"
  >
      <template v-slot:[`group.header`]="{ group, headers, toggle, isOpen }">
        <td :colspan="headers.length">
          <v-row dense>
            <v-btn @click="toggle" small icon :ref="group" :data-open="isOpen">
              <v-icon>{{ isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
            <v-col>{{ group }}</v-col>
            <v-col style="display: grid; justify-content: flex-end; padding-right: 30px;">
              <span style="display: inline-block">
                Total Missed Allowance: ${{ sumMissedAllowance(group) }}
              </span>
            </v-col>
          </v-row>
        </td>
      </template>
      <template #[`item.missed_allowance`]="{item}">
        <span>${{formatCurrencyDisplay(item.missed_allowance)}}</span>
      </template>
      <template #[`item.variance`]="{item}">
        <span>${{formatCurrencyDisplay(item.variance)}}</span>
      </template>
      <template #[`item.promo_unit_price`]="{item}">
        <span>${{formatCurrencyDisplay(item.promo_unit_price)}}</span>
      </template>
      <template #[`item.basket_unit_price`]="{item}">
        <span>${{formatCurrencyDisplay(item.basket_unit_price)}}</span>
      </template>
    </v-data-table>
  <BatchTableFooter
    :currentPage="currentPage"
    :itemsPerPage="pageSize"
    :hasNextPage="hasNextPage"
    :itemsPerPageOptions="pageSizes"
    @previous-page="previousPage"
    @next-page="nextPage"
    @update:items-per-page="updatePageSize"
  />
  </v-card>

</template>

<script>
// Components
import ToggleExpanded from '@/components/ToggleExpanded.vue'
import BatchTableFooter from '@/components/BatchTables/SharedElements/BatchTableFooter.vue';
// Mixins
import { fullWidth } from '@/mixins/fullWidth'
import { format } from '@/mixins/format'
import { displayAlert } from '@/mixins/displayAlert'

export default {
  name: 'ineligible-table',
  data () {
    return {
      filter: '',
      loading: false,
      ineligible_items: [],
      currentPage: 1,
      totalItems: 0,
      pageSize: 50,
      pageSizes: [50, 100, 200],
      offset: 0, 
      hasNextPage: true, 
    };
  },
  components: { ToggleExpanded, BatchTableFooter },
  mixins: [fullWidth, format, displayAlert],
  props: ['panel_height', 'selectedBatch'],
  watch: {
    filter: {
      handler () {
        if (this.filter === null) {
          this.filter = ''
        }
      }
    },
    panel_height: {
      handler (value) {
        this.nonTableHeight = value
      } 
    }
  },
  created () {
    this.nonTableHeight = this.panel_height
    this.fetchData();
  },
  computed: {
    headers () {
      return [
        { sortable: false, filterable: false },
        { text: 'Promo Name', align: 'left', sortable: true, filterable: true, value: 'promo_name', class: 'black--text' },
        { text: 'Item ID', align: 'left', sortable: true, filterable: true, value: 'item_id', class: 'black--text' },
        { text: 'UPC', align: 'left', sortable: true, filterable: true, value: 'upc', class: 'black--text' },
        { text: 'Promotion Price', align: 'left', sortable: false, filterable: false, value: 'promo_unit_price', class: 'black--text' },
        { text: 'Retail Price', align: 'left', sortable: true, filterable: true, value: 'basket_unit_price', class: 'black--text' },
        { text: 'Variance', align: 'left', sortable: true, filterable: true, value: 'variance', class: 'black--text' },
        { text: 'Missed Allowance', align: 'left', sortable: false, filterable: false, value: 'missed_allowance', class: 'black--text' }
      ]
    }
  },
  methods: {
    sumMissedAllowance(value) {
      const allowances = this.ineligible_items.flatMap(result => {
        return (result?.store_name_number === value) ? result.missed_allowance : []
      })
      const sum = allowances.reduce((total, value) => {
        return total + value
      }, 0)
      return this.formatCurrencyDisplay(sum)
    },
    async fetchData() {
      const { id } = this.selectedBatch;
      this.loading = true;

      try {
        const response = await this.$BillingBatch.getIneligibleRecap(id, this.pageSize, this.offset);
        if (response && response.data) {
          this.ineligible_items = response.data;
          this.hasNextPage = response.data.length === this.pageSize; 
        } else {
          this.ineligible_items = [];
          this.hasNextPage = false;
        }
      } catch (err) {
        console.error(err);
        this.handleError(err);
      } finally {
        this.loading = false;
      }
    },
    updatePage(page) {
      this.currentPage = page;
      this.fetchData();
    },
    updatePageSize(size) {
      this.pageSize = size;
      this.currentPage = 1; 
      this.fetchData();
    },
    nextPage() {
      if (this.hasNextPage) {
        this.offset += this.pageSize; 
        this.currentPage++; 
        this.fetchData();
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.offset -= this.pageSize; 
        this.currentPage--; 
        this.fetchData();
      }
    }
  }
};
</script>

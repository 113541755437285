var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-toolbar",
    { staticClass: "py-1", attrs: { flat: "", color: "toolbar" } },
    [
      _c(
        "v-row",
        { staticClass: "w-100", attrs: { align: "center", justify: "end" } },
        [
          _c(
            "v-col",
            {
              staticClass: "d-flex align-center",
              staticStyle: { "max-width": "150px" },
              attrs: { cols: "auto" },
            },
            [
              _c("v-select", {
                staticClass: "pa-0",
                attrs: {
                  dense: "",
                  items: _vm.itemsPerPageOptions,
                  label: "Rows per page",
                  "menu-props": { top: true, offsetY: true, maxHeight: 500 },
                  outlined: "",
                  "hide-details": "",
                },
                on: { change: _vm.updateItemsPerPage },
                model: {
                  value: _vm.localItemsPerPage,
                  callback: function ($$v) {
                    _vm.localItemsPerPage = $$v
                  },
                  expression: "localItemsPerPage",
                },
              }),
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "mx-2 pa-0",
              attrs: { icon: "", small: "", disabled: _vm.currentPage === 1 },
              on: { click: _vm.goToPreviousPage },
            },
            [
              _c("v-icon", { attrs: { small: "" } }, [
                _vm._v("mdi-chevron-left"),
              ]),
            ],
            1
          ),
          _c(
            "small",
            {
              staticClass: "text-center",
              staticStyle: { "line-height": "1.2", "font-size": "14px" },
            },
            [_vm._v("Page " + _vm._s(_vm.currentPage))]
          ),
          _c(
            "v-btn",
            {
              staticClass: "mx-2 pa-0",
              attrs: { icon: "", small: "", disabled: !_vm.hasNextPage },
              on: { click: _vm.goToNextPage },
            },
            [
              _c("v-icon", { attrs: { small: "" } }, [
                _vm._v("mdi-chevron-right"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <v-toolbar flat color="toolbar" class="py-1">
    <!-- Pagination controls -->
    <v-row align="center" justify="end" class="w-100">
      <v-col cols="auto" class="d-flex align-center" style="max-width: 150px;">
        <v-select
          dense
          :items="itemsPerPageOptions"
          label="Rows per page"
          v-model="localItemsPerPage"
          :menu-props="{ top: true, offsetY: true, maxHeight: 500 }"
          outlined
          hide-details
          class="pa-0"
          @change="updateItemsPerPage"
        ></v-select>
      </v-col>
      <v-btn
        icon
        small
        @click="goToPreviousPage"
        :disabled="currentPage === 1"
        class="mx-2 pa-0"
      >
        <v-icon small>mdi-chevron-left</v-icon>
      </v-btn>
      <small class="text-center" style="line-height: 1.2; font-size: 14px;">Page {{ currentPage }}</small>
      <v-btn
        icon
        small
        @click="goToNextPage"
        :disabled="!hasNextPage"
        class="mx-2 pa-0"
      >
        <v-icon small>mdi-chevron-right</v-icon>
      </v-btn>
    </v-row>
  </v-toolbar>
</template>

<script>
export default {
  name: "ToolbarPagination",
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      required: true,
    },
    hasNextPage: {
      type: Boolean,
      required: true,
    },
    itemsPerPageOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      localItemsPerPage: this.itemsPerPage,
    };
  },
  watch: {
    itemsPerPage(newVal) {
      this.localItemsPerPage = newVal;
    },
  },
  methods: {
    goToPreviousPage() {
      this.$emit("previous-page");
    },
    goToNextPage() {
      this.$emit("next-page");
    },
    updateItemsPerPage() {
      this.$emit("update:items-per-page", this.localItemsPerPage);
    },
  },
};
</script>
